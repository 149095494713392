import { localizedStrings } from '@core/strings'
import React from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useModal } from '@common/GlobalModal'
import { ModalDialogContainer } from '@common/modal/ModalDialogContainer'

type ComponentType = {
  title?: string
  onDismiss?: () => void
  onConfirm: () => void
  confirmText?: string
  dismissText?: string
  children?: React.ReactNode
  disabledConfirm?: boolean
  icon?: React.ReactNode
  dismissButton?: boolean
}

const ConfirmModal: React.FC<ComponentType> = ({
  title = localizedStrings.areYouSure,
  children,
  onDismiss,
  onConfirm,
  confirmText = localizedStrings.confirm,
  dismissText = localizedStrings.cancel,
  disabledConfirm,
  icon,
  dismissButton = true,
}) => {
  const { hideModal } = useModal()
  const onDismissClick = () => {
    if (onDismiss) {
      onDismiss()
    }
    hideModal()
  }

  return (
    <ModalDialogContainer>
      {title && (
        <DialogTitle
          variant='h5'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ pr: 1, fontSize: 20 }}
        >
          {title}
          <IconButton aria-label='close-modal' onClick={onDismissClick}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>
      )}

      <DialogContent>{children}</DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        {dismissButton && (
          <Button
            variant='outlined'
            onClick={onDismissClick}
            data-test='confirm-no'
          >
            {dismissText}
          </Button>
        )}
        <Button
          disabled={disabledConfirm}
          variant='contained'
          onClick={onConfirm}
          data-test='confirm-yes'
          endIcon={icon}
          disableElevation
          color={confirmText === localizedStrings.delete ? 'error' : 'primary'}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}

export default ConfirmModal
