import { localizedStrings } from '@core/strings'
import React, { useEffect } from 'react'
import { Flex, Text } from '@fundamentals'
import { useDeepCompareMemo } from 'use-deep-compare'
import {
  CheckCircleOutlined,
  InfoOutlined,
  TimelapseOutlined,
} from '@mui/icons-material'
import { Chip, Tooltip } from '@common/material'
import { ViewApprovalsPermitModal } from '@modules/permitDetails/modals/ViewApprovalsPermitModal'
import { ViewChangesDone } from '@modules/permitDetails/modals/ViewChangesDone'
import { ReasonForRejectionPermitModal } from '@modules/permitDetails/modals/ReasonForRejectionPermitModal'
import { ReasonForCancelPermitModal } from '@modules/permitDetails/modals/ReasonForCancelPermitModal'
import { useModal } from '@common/GlobalModal'
import { useRouter } from 'next/router'
import { usePermitTasks } from '@core/react-query/features/permits/permit'
import { Task } from '@core/entities/task/Task'
import { usePermitChips } from '@modules/permitDetails/usePermitChips'
import { Permit } from '@core/entities/permit/Permit'
import { ViewFinalSignOffsPermitModal } from '../modals/ViewFinalSignOffsPermitModal'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { ObjectDetectionRobotChip } from '@elements/ObjectDetection/ObjectDetectionRobotChip'

type ChipsProps = {
  permit: Permit
}

const Chips: React.FC<ChipsProps> = ({ permit }) => {
  const router = useRouter()
  const { showModal } = useModal()

  const { data: tasks, refetch } = usePermitTasks(
    { permitId: permit.id },
    {
      enabled: !!permit.id,
    },
  )

  const { primaryChip } = usePermitChips()

  const approvalsInCurrentStage = permit?.approvalReviews?.approvals?.filter(
    (a) =>
      a.approval.approvalStage === permit?.approvalReviews?.nextApprovalStage,
  )

  const chips = useDeepCompareMemo(() => {
    const chips = []
    chips.push(
      primaryChip(permit, {
        dataTest: 'permit-status-chip',
      }),
    )

    // Number of approvals pill when permit is awaiting approval
    if (
      permit.status === 'REQUESTED' &&
      approvalsInCurrentStage?.length > 0 &&
      (!permit?.lastRequestedChange || permit?.lastRequestedChange?.addressedBy)
    ) {
      const doneReviews = approvalsInCurrentStage?.filter(
        (a) => a?.action === 'APPROVED',
      )?.length

      const allReviews = approvalsInCurrentStage?.length
      if (allReviews > 1) {
        const tooltipContent = approvalsInCurrentStage?.map((a, index) => {
          const Icon = () =>
            a?.action === 'APPROVED' ? (
              <CheckCircleOutlined sx={{ pr: 0.5 }} />
            ) : (
              <TimelapseOutlined sx={{ pr: 0.5 }} />
            )
          const label =
            a?.action === 'APPROVED'
              ? `${a?.submittedBy?.firstName} ${a?.submittedBy?.lastName} (${a?.approval?.label})`
              : a?.approval?.label

          return (
            <Flex flexDirection='column' key={index}>
              <Flex alignItems='center'>
                <Icon />
                {label}
              </Flex>
            </Flex>
          )
        })

        chips.push(
          <Tooltip title={tooltipContent} arrow>
            <Chip
              variant='outlined'
              sx={{ border: 'none' }}
              icon={<InfoOutlined />}
              color={'info'}
              label={
                <Text color='info.main' sx={{ textDecoration: 'underline' }}>
                  {`${doneReviews} out of ${allReviews} - View Approvals`}
                </Text>
              }
              onClick={() => {
                showModal(
                  <ViewApprovalsPermitModal
                    approvals={approvalsInCurrentStage}
                  />,
                )
              }}
            />
          </Tooltip>,
        )
      }
    }

    // Number of finalSignOffs pill when permit is awaiting finalSignOff
    if (
      permit.status === 'AWAITING_FINAL_SIGN_OFF' &&
      permit?.finalSignOffReviews?.reviews?.length > 0
    ) {
      const doneReviewsIndex = permit.finalSignOffReviews.getDoneReviewsIndex()

      const allReviews = permit.finalSignOffReviews?.reviews?.length

      if (allReviews > 0) {
        const tooltipContent = permit?.finalSignOffReviews?.reviews?.map(
          ({ finalSignOff, createdBy }, index) => {
            const Icon = () =>
              index < doneReviewsIndex ? (
                <CheckCircleOutlined sx={{ pr: 0.5 }} />
              ) : (
                <TimelapseOutlined sx={{ pr: 0.5 }} />
              )
            const label =
              index < doneReviewsIndex
                ? `${createdBy?.getFullName()} (${finalSignOff?.label})`
                : finalSignOff?.label

            return (
              <Flex flexDirection='column' key={index}>
                <Flex alignItems='center'>
                  <Icon />
                  {label}
                </Flex>
              </Flex>
            )
          },
        )

        chips.push(
          <Tooltip title={tooltipContent} arrow>
            <Chip
              variant='outlined'
              sx={{ border: 'none' }}
              icon={<InfoOutlined />}
              color={'info'}
              label={
                <Text color='info.main' sx={{ textDecoration: 'underline' }}>
                  {`${doneReviewsIndex} out of ${allReviews} - View Final Sign Offs`}
                </Text>
              }
              onClick={() => {
                showModal(
                  <ViewFinalSignOffsPermitModal
                    finalSignOffReviews={permit.finalSignOffReviews}
                  />,
                )
              }}
            />
          </Tooltip>,
        )
      }
    }

    // Requested changes
    if (
      permit.status === 'REQUESTED' &&
      !!permit?.lastRequestedChange?.addressedBy
    ) {
      // Requested changes message
      chips.push(
        <Tooltip title={permit.lastRequestedChange.comment}>
          <Chip
            variant='outlined'
            sx={{ border: 'none' }}
            icon={<InfoOutlined />}
            color={'info'}
            label={
              <Text
                data-test='changes-chip-text'
                color='info.main'
                sx={{ textDecoration: 'underline' }}
              >
                {localizedStrings.changesDone}
              </Text>
            }
            onClick={() => showModal(<ViewChangesDone permit={permit} />)}
          />
        </Tooltip>,
      )
    }

    // Reject reason
    if (permit.status === 'REJECTED') {
      chips.push(
        <Tooltip title={permit?.rejectReason}>
          <Chip
            variant='outlined'
            sx={{ border: 'none' }}
            icon={<InfoOutlined />}
            color='info'
            label={
              <Text color='info.main' sx={{ textDecoration: 'underline' }}>
                {localizedStrings.viewReason}
              </Text>
            }
            onClick={() => {
              showModal(<ReasonForRejectionPermitModal permit={permit} />)
            }}
          />
        </Tooltip>,
      )
    }

    // Cancel reason
    if (permit.status === 'CANCELLED') {
      chips.push(
        <Tooltip title={permit?.cancelReason}>
          <Chip
            variant='outlined'
            sx={{ border: 'none' }}
            icon={<InfoOutlined />}
            color='info'
            label={
              <Text color='info.main' sx={{ textDecoration: 'underline' }}>
                {localizedStrings.viewReason}
              </Text>
            }
            onClick={() => {
              showModal(<ReasonForCancelPermitModal permit={permit} />)
            }}
          />
        </Tooltip>,
      )
    }

    if (permit?.warnings?.length) {
      chips.push(
        <Flex alignItems='center' sx={{ mx: 1 }}>
          <WarningAmberOutlinedIcon color='warning' />
          <Text variant='body2' sx={{ ml: 0.5, fontWeight: 500 }}>{`${
            permit?.warnings?.length
          } Warning${permit?.warnings?.length > 1 ? 's' : ''}`}</Text>
        </Flex>,
      )
    }

    const numberOfOutstandingTasks = Task.getNumberOfTasksByStatus(
      tasks || [],
      'TO_DO',
    )
    if (
      numberOfOutstandingTasks > 0 &&
      !['SUSPENDED', 'SUSPENDED_RESUME_REQUESTED'].includes(permit.status)
    ) {
      chips.push(
        <Chip
          icon={<InfoOutlined />}
          color='info'
          label={`${numberOfOutstandingTasks} Task${
            numberOfOutstandingTasks > 1 ? 's' : ''
          } Outstanding`}
          onClick={() => {
            router.query.tab = 'Tasks'
            router.push(router, undefined, { shallow: true })
          }}
        />,
      )
    }

    return chips
  }, [permit, tasks])

  useEffect(() => {
    // re-fetch when permit is approved and status is updated
    if (permit?.status === 'ISSUED' && tasks?.length === 0) {
      refetch()
    }
  }, [permit, tasks, refetch])

  return (
    <Flex gap={1} alignItems={'center'}>
      {chips}
      <ObjectDetectionRobotChip permit={permit} />
    </Flex>
  )
}

export default Chips
