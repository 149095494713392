import { ControllerRenderProps, RegisterOptions } from 'react-hook-form'
import {
  ITemplateQuestion,
  SubmissionDocument,
  SubmissionProof,
} from '@core/entities/template/TemplateQuestion'
import { ITemplateQuestionGroup } from '@core/entities/template/TemplateQuestionGroup'
import { ITemplateRule } from '@core/entities/template/TemplateRule'
import { ITemplateProjectLocations } from '@core/entities/template/TemplateProjectLocations'
import { PermitWarning } from '@core/entities/template/TemplateWarnings'

export enum AttachmentTypes {
  CAMERA = 'CAMERA',
  IMAGE = 'image',
  PDF = 'pdf',
  CSV = 'csv',
  KMZ = 'kmz',
}

export type TemplateAnswersQuestionsFormValues<
  questionsKeys extends string = 'questions',
> = {
  [questionsKey in questionsKeys]: Array<ITemplateQuestionGroup>
} & {
  rules?: Array<ITemplateRule>
  hiddenQuestionIds?: Array<string>
  projectId?: number
  permitId?: number
}

export type TemplateAnswersFormValues<
  TemplatePicker,
  questionsKeys extends string = 'questions',
> = TemplateAnswersQuestionsFormValues<questionsKeys> & {
  projectId: number
  template: TemplatePicker
  projectLocations?: ITemplateProjectLocations
  summary: string
  registerId?: number
  registerItemId?: number

  draftId?: number
  draftName?: string
}

export type QuestionGroupProps = {
  groupIndex: number
  questionGroup?: ITemplateQuestionGroup
  hiddenQuestionIds?: Set<string>
  questionsFormKey?: string
  readOnly?: boolean
  permitId?: number
  organisationId?: number
  warnings?: Array<PermitWarning>
}

export type QuestionProps = {
  groupIndex: number
  questionIndex: number
  questionNumber: number
  questionsFormKey?: string
  answer?: ITemplateQuestion
  readOnly?: boolean
  footer?: React.ReactNode
  actionButton?: React.ReactNode
  attachmentTypes?: AttachmentTypes[]
  attachments?: Array<SubmissionProof | SubmissionDocument>
  warnings?: Array<PermitWarning>
}

export type QuestionTypeProps<
  FieldType = {},
  QuestionType = ITemplateQuestion & { [key: string]: any },
> = {
  question: QuestionType
  inputProps?: FieldType &
    Partial<
      Omit<
        ControllerRenderProps<{
          [key: string]: QuestionType
        }>,
        'onChange'
      >
    > & {
      onChange: (value: QuestionType) => void
    }
  error?: string
  optional?: boolean
  readOnly?: boolean
}

export type QuestionTypeComponentType<FieldType = {}> = {
  rules?: RegisterOptions
} & React.FC<QuestionTypeProps<FieldType>>
