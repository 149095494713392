import { localizedStrings } from '@core/strings'
import { Entity } from '@core/entities/entity'
import { IAuditResult, IAuditResultMetadata } from './IAuditResult'
import { AuditAction } from '@core/entities/audit/AuditResult/types'
import { IUser, User } from '@core/entities/user'
import { Exclude, plainToInstance, Type } from 'class-transformer'
import { PermitSuspensionReasons } from '@core/entities/permit/PermitSuspension'
import { RegisterItemWithLinksPropertyData } from '@core/entities/register/RegisterItem'
import moment from 'moment'
import { Feature, GeoJSON, Point } from 'geojson'
import { Polygon, Units, circle } from '@turf/turf'
import { CommentSecondaryReferenceObjectTypes } from '@core/entities/comments'
import { TemplateQuestion } from '@core/entities/template/TemplateQuestion'
import { PermitWarning } from '@core/entities/template/TemplateWarnings'
import { AuthGroup } from '@core/entities/Groups/AuthGroups'

class AuditResultMetadata extends Entity<IAuditResultMetadata> {
  mediaUrls?: Array<string>
  permitRoleName?: string
  checklistRoleName?: string
  permitGeoJson?: GeoJSON
  distanceToPermitInMeters?: number
  transferReceiver?: IUser
  taskChecklistId?: number
  suspensionReasonCategory?: PermitSuspensionReasons
  delta: {
    itemValues: RegisterItemWithLinksPropertyData[]
  }
  postValues: {
    itemValues: RegisterItemWithLinksPropertyData[]
  }
  preValues: {
    itemValues: RegisterItemWithLinksPropertyData[]
  }
  updateNote?: string
  inputType?: string
  commentSecondaryReferenceObjectId: number
  commentSecondaryReferenceObjectIdText: string
  commentSecondaryReferenceObjectType: CommentSecondaryReferenceObjectTypes

  @Type(() => TemplateQuestion)
  commentAnsweredQuestionDataResult: TemplateQuestion

  offlineSubmittedOn?: string

  @Exclude({ toPlainOnly: true })
  mediaUrlsWithType?: Array<{ getUrl: string; type: string }>
  @Type(() => PermitWarning)
  warnings?: Array<PermitWarning>
  emailAddress?: string
}

export class AuditResult extends Entity<IAuditResult> {
  id: number
  action: AuditAction
  platform: 'WEB' | 'ANDROID' | 'IOS' | 'UNKNOWN'
  signatureUrl?: string
  info?: string
  createdOn?: string
  @Type(() => User)
  user?: User
  @Type(() => User)
  organisationUser?: User
  @Type(() => User)
  appliedToUser?: User
  @Type(() => User)
  projectUser?: User
  @Type(() => AuditResultMetadata)
  metadata: AuditResultMetadata
  geoJSON?: GeoJSON
  project?: {
    id: number
    name: string
  }
  organisation?: {
    id: number
    name: string
  }
  permit?: {
    id: number
  }
  checklist?: {
    id: number
  }
  register?: {
    id: number
    name: string
  }
  registerItem?: {
    id: number
    uniqueId: string
  }
  @Type(() => AuthGroup)
  group?: AuthGroup

  public static new(payload: unknown): AuditResult {
    const entity = plainToInstance(AuditResult, payload)

    return entity
  }

  public getActionLabel(): string {
    switch (this.action) {
      case 'PERMIT_APPROVED':
        return localizedStrings.approved
      case 'PERMIT_RECEIVED':
        return localizedStrings.permitReceived
      case 'PERMIT_REQUESTED':
        return localizedStrings.requested
      case 'CHECKLIST_COMPLETED':
        return localizedStrings.complete
      case 'PERMIT_OPENED':
        return localizedStrings.opened
      case 'PERMIT_POST_PERMIT_CHECK_COMPLETE':
        return localizedStrings.postPermitChecksComplete
      case 'PERMIT_SIGN_OFF_COMPLETE':
        return localizedStrings.finalSignOff
      case 'WORK_COMPLETE':
        return localizedStrings.permitClosed
      case 'PERMIT_TRANSFER_CANCELLED':
        return localizedStrings.transferCanceled
      case 'PERMIT_SIGNED':
        return localizedStrings.permitSigned
      case 'PERMIT_CHANGES_ADDRESSED':
        return localizedStrings.changesAddressed
      case 'PERMIT_PERIODIC_CHECKS_SUBMITTED':
        return localizedStrings.periodicCheckSubmitted
      case 'PERMIT_CHECKIN':
        return localizedStrings.checkin
      case 'PERMIT_DRAFT_CREATED':
      case 'CHECKLIST_DRAFT_CREATED':
        return localizedStrings.draftCreated
      case 'PARTICIPANT_REMOVED':
        return localizedStrings.participantRemoved
      case 'PERMIT_CHECKLIST_DONE':
        return localizedStrings.checklistCompleted
      case 'PARTICIPANT_BRIEFED':
        return localizedStrings.participantBriefed
      case 'PERMIT_EXTENSION_APPROVED':
        return localizedStrings.extensionApproved
      case 'PERMIT_TRANSFER_ACCEPTED':
        return localizedStrings.transferAccepted
      case 'PERMIT_TRANSFER_APPROVED':
        return localizedStrings.permitTransferApproved
      case 'PERMIT_TRANSFER_DECLINED':
        return localizedStrings.permitTransferDeclined
      case 'SENT_TO_PERMIT_HOLDER':
        return localizedStrings.sentToPermitHolder
      case 'PERMIT_EXTENSION_REQUESTED':
        return localizedStrings.extensionsRequested
      case 'PERMIT_CHANGES_REQUESTED':
        return localizedStrings.changesRequested
      case 'PERMIT_TRANSFER_INITIATED':
        return localizedStrings.transferInitiated
      case 'PERMIT_HOLDER_CHANGED':
        return localizedStrings.permitHolderChanged
      case 'PERMIT_REJECTED':
        return localizedStrings.permitRejected
      case 'PERMIT_EXTENSION_REJECTED':
        return localizedStrings.extensionRejected
      case 'PERMIT_TRANSFER_REJECTED':
        return localizedStrings.transferRejected
      case 'PERMIT_CANCELLED':
        return localizedStrings.cancelled
      case 'PERMIT_SUSPENDED':
        return localizedStrings.suspended
      case 'PERMIT_SUSPENDED_RESUME':
        return localizedStrings.resumed
      case 'PERMIT_SUSPENDED_RESUME_REQUESTED':
        return localizedStrings.resumeRequested
      case 'PERMIT_SUSPENSION_RESUME_REQUEST_DECLINED':
        return localizedStrings.resumeRequestRejected
      case 'PERMIT_SUSPENSION_RESUME_REQUEST_APPROVED':
        return localizedStrings.resumeRequestApproved
      case 'PERMIT_SHORTENED':
        return localizedStrings.shortened
      case 'PERMIT_DISCARDED':
        return localizedStrings.discarded
      case 'PERMIT_EXPIRED':
        return localizedStrings.expired
      case 'TASK_CANCELLED':
        return localizedStrings.cancelled
      case 'PROJECT_MEMBER_ADDED':
        return localizedStrings.addedUserToProject
      case 'PROJECT_MEMBER_REMOVED':
        return localizedStrings.removedUserFromProject
      case 'PROJECT_MEMBER_ACTIVATED':
        return localizedStrings.activatedProjectMember
      case 'PROJECT_MEMBER_DISABLED':
        return localizedStrings.disabledProjectMember
      case 'PROJECT_MEMBER_ROLE_UPDATED':
        return localizedStrings.updatedProjectPermissions
      case 'USER_ADDED_TO_ORGANISATION':
        return localizedStrings.addedUserToOrganisation
      case 'USER_PROFILE_CHANGED':
        return localizedStrings.changedProfileInformation
      case 'USER_ROLE_UPDATED_IN_ORGANISATION':
        return localizedStrings.updatedOrganisationPermissions
      case 'ORGANISATION_MEMBER_ACTIVATED':
        return localizedStrings.activatedOrganisationMember
      case 'ORGANISATION_MEMBER_DISABLED':
        return localizedStrings.disabledOrganisationMember
      case 'CHECKLIST_TEMPLATE_VERSION_ACTIVATED':
        return localizedStrings.activatedChecklistVersion
      case 'CHECKLIST_TEMPLATE_VERSION_CREATED':
        return localizedStrings.createdChecklistVersion
      case 'CHECKLIST_TEMPLATE_UPDATED':
        return localizedStrings.updatedChecklistTemplate
      case 'TEMPLATE_VERSION_CREATED':
        return localizedStrings.createdPermitTemplateVersion
      case 'TEMPLATE_VERSION_ACTIVATED':
        return localizedStrings.activatedPermitTemplateVersion
      case 'TEMPLATE_VERSION_UPDATED':
        return localizedStrings.updatedPermitTemplateVersion
      case 'TEMPLATE_CREATED':
        return localizedStrings.createdPermitTemplate
      case 'TEMPLATE_UPDATED':
        return localizedStrings.updatedPermitTemplate
      case 'USER_ADDED_TO_ORGANISATION_AUTH_GROUP':
      case 'USER_ADDED_TO_PROJECT_AUTH_GROUP':
        return localizedStrings.addedUserToAuthGroup
      case 'USER_REMOVED_FROM_ORGANISATION_AUTH_GROUP':
      case 'USER_REMOVED_FROM_PROJECT_AUTH_GROUP':
        return localizedStrings.removedUserFromAuthGroup
      case 'PROJECT_PERMISSION_ASSIGNMENTS_UPDATED':
        return localizedStrings.updatedAuthGroupPermissions
      case 'PROJECT_TEMPLATE_ADDED':
        return localizedStrings.addedPermitTemplate
      case 'PROJECT_TEMPLATE_VERSION_UPDATED':
        return localizedStrings.updatedProjectPermitSettings
      case 'PROJECT_APPROVAL_ASSIGNMENTS_UPDATED':
        return localizedStrings.updatedPermitApprovalSettings
      case 'CREATED':
        return localizedStrings.itemCreated
      case 'UPDATED':
        return localizedStrings.itemUpdated
      case 'COMMENT_CREATED':
        return localizedStrings.newComment
      case 'IMAGE_WARNINGS_CREATED':
        return localizedStrings.warningDetected
      default:
        return this.action
    }
  }

  public getActionColor(): string {
    switch (this.action) {
      case 'CHECKLIST_COMPLETED':
      case 'PERMIT_POST_PERMIT_CHECK_COMPLETE':
      case 'PERMIT_SIGN_OFF_COMPLETE':
      case 'WORK_COMPLETE':
      case 'PERMIT_SIGNED':
      case 'PERMIT_PERIODIC_CHECKS_SUBMITTED':
      case 'PERMIT_CHECKIN':
      case 'PERMIT_DRAFT_CREATED':
      case 'CHECKLIST_DRAFT_CREATED':
      case 'PERMIT_CHECKLIST_DONE':
      case 'PERMIT_SUSPENDED_RESUME_REQUESTED':
        return 'primary.main'

      case 'PERMIT_OPENED':
      case 'PARTICIPANT_BRIEFED':
      case 'PERMIT_EXTENSION_APPROVED':
      case 'PERMIT_TRANSFER_ACCEPTED':
      case 'PERMIT_TRANSFER_APPROVED':
      case 'PERMIT_CHANGES_ADDRESSED':
      case 'PERMIT_APPROVED':
      case 'PERMIT_RECEIVED':
      case 'PERMIT_SUSPENDED_RESUME':
      case 'PERMIT_SUSPENSION_RESUME_REQUEST_APPROVED':
      case 'CREATED':
      case 'UPDATED':
        return 'success.main'

      case 'PERMIT_REQUESTED':
      case 'SENT_TO_PERMIT_HOLDER':
      case 'PERMIT_EXTENSION_REQUESTED':
      case 'PERMIT_CHANGES_REQUESTED':
      case 'PERMIT_TRANSFER_INITIATED':
      case 'PERMIT_HOLDER_CHANGED':
      case 'IMAGE_WARNINGS_CREATED':
        return 'warning.main'

      case 'PERMIT_REJECTED':
      case 'PERMIT_EXTENSION_REJECTED':
      case 'PERMIT_TRANSFER_REJECTED':
      case 'PERMIT_TRANSFER_DECLINED':
      case 'PERMIT_TRANSFER_CANCELLED':
      case 'PARTICIPANT_REMOVED':
      case 'PERMIT_CANCELLED':
      case 'PERMIT_SUSPENDED':
      case 'PERMIT_SUSPENSION_RESUME_REQUEST_DECLINED':
      case 'PERMIT_SHORTENED':
      case 'PERMIT_DISCARDED':
      case 'PERMIT_EXPIRED':
      case 'TASK_CANCELLED':
        return 'error.main'

      case 'COMMENT_CREATED':
        return 'info.main'

      default:
        return 'grey'
    }
  }

  public hasAnyAuditActions(actions: Array<AuditAction>): boolean {
    return actions.some((action) => this.action === action)
  }

  getRegisterItemPropertyValues(
    registerItemProperty: RegisterItemWithLinksPropertyData,
  ): string {
    switch (registerItemProperty.type) {
      case 'STRING':
        return registerItemProperty?.textValue ?? ''
      case 'SELECT':
        return registerItemProperty?.selectedOption?.label ?? ''
      case 'LINK':
        return registerItemProperty?.parentRegisterItem?.uniqueId ?? ''
      case 'DATE':
        const dateRange = registerItemProperty?.dateValue
          ? moment.utc(registerItemProperty?.dateValue).format('D MMM YYYY')
          : ''
        const startDate = registerItemProperty?.startTime
          ? moment(registerItemProperty?.startTime).format('do MMM yyyy')
          : ''
        const endDate = registerItemProperty?.endTime
          ? moment(registerItemProperty?.endTime).format('do MMM yyyy')
          : ''
        return dateRange || startDate || endDate
      case 'NUMBER':
        return String(registerItemProperty?.numberValue ?? '')
      case 'USER':
        const user = registerItemProperty?.selectedUser
        return user ? `${user.firstName} ${user.lastName}` : ''
      default:
        return ''
    }
  }

  public getCreatedRegisterItemProperties(): any {
    switch (this.action) {
      case 'CREATED':
        return this.metadata.delta.itemValues
          .map((item: any) => ({
            name: item.postValue.name,
            value: this.getRegisterItemPropertyValues(item.postValue),
          }))
          .filter((item: any) => !!item.value)

      case 'UPDATED':
      case 'FORMULAS_RECALCULATED':
        return this.metadata.delta.itemValues.map((item: any) => ({
          name: item.postValue.name,
          value: this.getRegisterItemPropertyValues(item.postValue),
          preValue: this.getRegisterItemPropertyValues(item.preValue),
        }))
      default:
        return null
    }
  }

  public getCoordinates(): Feature<Point> {
    if (!(this.geoJSON && this.geoJSON.type === 'FeatureCollection')) return
    const { geometry, ...feature } = this.geoJSON.features[0]
    if (geometry.type !== 'Point') return
    return { geometry, ...feature }
  }

  public getAuditPointAccuracyRadius(): Feature<Polygon> {
    if (!(this.geoJSON && this.geoJSON.type === 'FeatureCollection')) return
    const w3wFeaturePoint = this.geoJSON.features.find((feature) => {
      return feature.properties.inputType === 'WHAT_3_WORDS'
    }) as Feature<Point>

    const turfCircle =
      w3wFeaturePoint?.properties?.locationAccuracyInMeters &&
      circle(
        w3wFeaturePoint,
        w3wFeaturePoint?.properties?.locationAccuracyInMeters,
        {
          steps: 50,
          units: 'meters' as Units,
        },
      )

    return turfCircle
  }

  public getPermitCoordinates(): Feature<Point> {
    const { permitGeoJson } = this.metadata
    if (!(permitGeoJson && permitGeoJson.type === 'FeatureCollection')) return
    const { geometry, ...feature } = permitGeoJson.features[0]
    if (geometry.type !== 'Point') return
    return { geometry, ...feature }
  }
}
